<template>
  <div class>
    <div class="testimonal-card mt-1">
      <div
        class="grid grid-cols-3 grid-rows-7 grid-flow-row overflow-hidden rounded-xl box-shadow bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out p-4"
      >
        <div class="col-span-3 row-span-4 py-2 m-1 mt-4">
          <h4 class="text-dark font-bold has-font-circular-bold">{{title}}</h4>
          <!-- <a href="#">
                        <img src="../../../assets/images/Face-Detection.jpg" alt="Placeholder" class="object-cover h-36 w-full" />
          </a>-->
        </div>

        <div class="col-span-3 row-span-1 m-1">
          <div class="flex items-center leading-none text-box h-64 sm:h-72">
            <p class="text-light-gray px-3">"{{testimonial}}"</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    title: String,
    testimonial: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/Variables.scss";

.testimonal-card {
  width: 28rem;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 20rem;
  }
}

.text-box {
  background-color: $background-color-fourth;
}

p {
  font-family: $font-circularStd-book;
  // line-height: 1.2;
  @media (max-width: 500px) {
    font-size: 0.8em;
  }
}
</style>