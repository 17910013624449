<template>
    <div class="pt-5">
        <div class="slide-title  my-12 md:my-18">Testimonials.</div>
        <swiper class="swiper" :options="swiperOption" >
            <swiper-slide class="swiper-slide">
                <testimonal-card 
                    :title="'Nutrifit, USA'" 
                    :testimonial="'We began working with Magure to take over the development of our new client interface. They stepped in quickly to understand our complicated software and completed the project. They have since worked on several other improvements to our administrative & client portals as well as all other general sites. Magure team is responsive, professional, and great to work with.'"/>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
                <testimonal-card 
                    :title="'Lotus Labs, USA'" 
                    :testimonial="'I am really impressed by the quality of services we received from Magure. They are professional, courteous, work collaboratively and produce deliverables on time with high quality. We are working with them since two years and I highly recommend their services!'"/>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
                <testimonal-card 
                    :title="'Be There, USA'" 
                    :testimonial="'Magure team has been exemplary of a world-class development firm. The associates are incredibly professional, fast-paced, and accommodating towards every project I\'ve worked with them on. I intend to use Magure Softwares for every future tech company I start. The results are always top-notch.'"/>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
                <testimonal-card 
                    :title="'Fixer Assistenza, Italy'" 
                    :testimonial="'Completing this app with the Magure team has been the most incredibly amazing journey. The team is very professional, extremely trustable, and most importantly, reliable. I knew that from the beginning that choosing them would be the best choice to do. And I was right. During the work process, we built trust and share ideas on creating the best product. I appreciate it and we are all very happy with what we have developed.'"/>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
                <testimonal-card 
                    :title="'Starshine, India'" 
                    :testimonial="'Magure team is highly professional and values time. I have never seen such an incredible team of highly skilled and talented developers'"/>
            </swiper-slide>

            <div class="swiper-pagination-H flex justify-end my-5" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
    import "swiper/swiper-bundle.min.css";
    import TestimonalCard from './Testimonal-card.vue';

export default {
    components:{
        Swiper,
        SwiperSlide,
        TestimonalCard,
    },
    data() {
        return {
            swiperOption: {
            // direction: 'vertical',
            slidesPerView: 3,
            mousewheel: false,
            loop: false,
            pagination: {
                el: '.swiper-pagination-H',
                clickable: true
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            speed: 500,
            breakpoints: {
            1024: {
            slidesPerView: 2,
            spaceBetween: 40
            },
            768: {
            slidesPerView: 1,
            spaceBetween: 30
            },
            640: {
            slidesPerView: 1,
            spaceBetween: 15
            },
            320: {
            slidesPerView: 1,
            spaceBetween: 5
            }
            }
        }
        }
    }
}
</script>

<style>

</style>